<template>
  <div class="schedule-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="content">
          <!-- <router-view></router-view> -->
          <div class="calendar">
            <Calendar @getFormDate="getFormDate" />
          </div>

          <div class="course-content">
            <!-- <router-view></router-view> -->
            <template v-for="(item, index) in scheduleList">
              <ClassBox
                :courseClassify="item.courseStatus"
                :classListInfo="item"
                :key="index"
              />
            </template>
            <!-- <div
              class="pagination"
              v-if="currentPage === 1 && currentClassListInfo.length"
            >
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPageIndex"
                :page-sizes="[3, 5, 10]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalPage"
                background
              >
              </el-pagination>
            </div> -->
          </div>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import ClassBox from "@/components/Page/ClassBox";
import Calendar from "@/components/Page/Calendar";
import { getKidsCourseData } from "@/assets/js/axios/kidsApi";
import { getFormatDate } from "@/utils/tools";

export default {
  name: "Schedule",
  components: {
    PageLayout,
    ClassBox,
    Calendar,
  },
  data() {
    return {
      currentPage: 1,
      scheduleList: [],
      navList: [
        {
          title: "Schedule",
          id: 1,
        },
      ],
      rangeNum: 1,
    };
  },
  created() {
    // to do list
    this.getUpcomingClass();
  },
  methods: {
    getFormDate(dateTest, formDate) {
      this.getUpcomingClass()
    },
    changePage(index) {
      this.currentPage = index;
    },
    async getUpcomingClass() {
      const eventStarttime =
        getFormatDate(new Date()).split(" ")[0] + " 00:00:00";
      const nextDay = new Date(
        new Date().setDate(new Date().getDate() + this.rangeNum)
      );
      const eventEndtime = getFormatDate(nextDay).split(" ")[0] + " 00:00:00";
      const sendData = {
        eventStarttime,
        eventEndtime,
      };
      const { data } = await getKidsCourseData(sendData);
      console.log("UpcomingClass:", data);
      this.calendarEventId = data ? data[0].id : null;
      console.log(this.calendarEventId);
      this.getUserCourse(data);
    },
    getUserCourse(data) {
      this.scheduleList = data.map((item) => {
        return {
          startTime: this.getFormDate(item.eventStarttime),
          classType: item.classType === 1 ? "1-on-1 Online Class • 25 Min" : "",
          teacherName: item.teacherName + " | " + item.teacherPyName,
          teacherAvatar: item.teacherAvatar,
          levelIndex: item.levelNo,
          chapterIndex: item.lessonNo,
          chapterTitle: item.lessonTitle,
          chapterTitleEn: item.lessonTitleEn,
          // lessonIndex: item.kidsBook.lessonNo,
          lessonIndex: 1, //模拟
          lessonTitleHanzi: item.kidsBook.titleZh,
          lessonTitlePinyin: item.kidsBook.titlePy,
          demo: "Demo Class 25Min",
          // lessonUrl: "lesson1",
          courseStatus: item.eventState === 2 ? 0 : 1,
          calendarEventId: item.id,
        };
      });
    },

    getFormDate(date) {
      const dateArr = String(new Date(date)).split(" ");
      return (
        dateArr[0] +
        ", " +
        dateArr[1] +
        " " +
        dateArr[2] +
        " at " +
        date.split("T")[1].split(".")[0].split(":")[0] +
        ":" +
        date.split("T")[1].split(".")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/views/Client/Home/index.scss";
.schedule-container {
  // margin-top: 2%;
  // background: chocolate;
  .active {
    color: #224e96;
  }

  .nav-title {
    display: flex;

    .nav-title-item {
      cursor: default;
      margin-right: 5%;
      &:not(.active) {
        cursor: pointer;
      }
      a {
        color: #9e9e9f;
      }
    }
  }
  .content {
    width: 100%;
    // height: 100%;
    height: 75vh;
    // background: #fff;
    .calendar {
      background: #f1f1f0;
      margin-top: 1%;
      padding: 1% 0%;
      border-radius: 5px;
      margin-bottom: 3%;
    }
  }
}
</style>
